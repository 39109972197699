import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "listeHVWAllianz _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_vm._l(_vm.FILTER, function (v, i) {
    return _c('label', {
      key: i + 'obl' + i,
      staticClass: "lvi",
      class: {
        _sel: v
      },
      attrs: {
        "for": (v || {}).name + 'obl' + i
      }
    }, [v ? _c('i', {
      staticClass: "icon-check"
    }) : !v ? _c('i', {
      staticClass: "icon-circle"
    }) : _vm._e(), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.FILTER[i],
        expression: "FILTER[i]"
      }],
      staticClass: "check",
      attrs: {
        "type": "checkbox",
        "id": (v || {}).name + 'obl' + i
      },
      domProps: {
        "checked": Array.isArray(_vm.FILTER[i]) ? _vm._i(_vm.FILTER[i], null) > -1 : _vm.FILTER[i]
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.FILTER[i],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.FILTER, i, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.FILTER, i, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.FILTER, i, $$c);
          }
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(_vm.trans[i]))])]);
  }), _c('ul', {
    staticClass: "reminder"
  }, _vm._l(_vm.filteredReminders, function (item, id) {
    return _c('li', {
      staticClass: "reminder"
    }, [_c('div', {
      staticClass: "card",
      class: {
        _over: _vm.$dayjs(item.until).unix() * 1000 <= Date.now() && !item.done,
        _done: item.done
      }
    }, [_c('div', {
      staticClass: "fl"
    }, [_c('div', {
      staticClass: "inl"
    }, [_c('h4', [_c('span', {
      staticClass: "bold overdue"
    }, [_vm._v(_vm._s(_vm.$dayjs(item.until || 0).format('DD.MMM.YY')))])])])]), _c('div', {
      staticClass: "fr"
    }, [_c('div', {
      staticClass: "inl"
    }, [_c('span', [_vm._v("   ")]), _c('small', {
      staticClass: "bold"
    }, [_vm._v("erstellt: " + _vm._s(_vm.$dayjs(item.created || item.modified || 0).format('DD.MMM.YY')) + " (" + _vm._s((_vm._fbUsers[(item || {}).by || ''] || {}).firstname) + " " + _vm._s((_vm._fbUsers[(item || {}).by || ''] || {}).lastname) + ")")])])]), _c('div', {
      staticClass: "input"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: (_vm.reminders[item.id] || {}).name,
        expression: "(reminders[item.id]||{}).name"
      }],
      staticClass: "full",
      domProps: {
        "value": (_vm.reminders[item.id] || {}).name
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.reminders[item.id] || {}, "name", $event.target.value);
        }
      }
    })]), _c('br'), _c('div', {
      staticClass: "btn _small _green",
      on: {
        "click": function click($event) {
          _vm.ShowItem = item.assigned, _vm.$modal.show('ShowWholeReminder');
        }
      }
    }, [_vm._v("zugewiesene Akte")]), _c('div', {
      staticClass: "btn _small",
      on: {
        "click": function click($event) {
          (_vm.reminders[item.id] || {}).done = !(_vm.reminders[item.id] || {}).done;
        }
      }
    }, [!(_vm.reminders[item.id] || {}).done ? _c('span', [_vm._v("Verstecken")]) : (_vm.reminders[item.id] || {}).done ? _c('span', [_vm._v("Wieder Eröffnen")]) : _vm._e()]), _vm.hasChanged(item.id, id) ? _c('div', {
      staticClass: "fr"
    }, [_c('div', {
      staticClass: "btn _small _red",
      on: {
        "click": function click($event) {
          return _vm.SaveReminder(item.id);
        }
      }
    }, [_vm._v("Speichern")])]) : _vm._e(), _c('div', {
      staticClass: "clear"
    })])]);
  }), 0)], 2)]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowWholeReminder",
      "name": "ShowWholeReminder",
      "adaptive": true,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Erinnerung"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowWholeReminder');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h4', [_vm._v("Zugewiesene Akte:")]), _c('ul', _vm._l(_vm.ShowItem, function (idx, i) {
    return _c('li', [_c('div', {
      staticClass: "fl"
    }, [((_vm._fbProjects[idx] || {}).akt || {}).polnr ? _c('router-link', {
      staticClass: "bold link",
      attrs: {
        "to": "/allianz/list/" + idx
      }
    }, [_c('span', [_vm._v(_vm._s(((_vm._fbProjects[idx] || {}).akt || {}).polnr))])]) : _vm._e()], 1), _c('div', {
      staticClass: "fr"
    }, [((_vm._fbProjects[idx] || {}).akt || {}).polnr ? _c('router-link', {
      staticClass: "bold link",
      attrs: {
        "to": "/allianz/hvw/" + (_vm._fbProjects[idx] || {}).collection
      }
    }, [_c('span', [_vm._v(" (" + _vm._s((_vm._fbHausverwaltungen[(_vm._fbProjects[idx] || {}).collection] || {}).name) + ")")])]) : _vm._e()], 1), _c('div', {
      staticClass: "clear"
    })]);
  }), 0)]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _m _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide("ShowWholeReminder");
      }
    }
  }, [_vm._v("Schließen")])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Erinnerungen")])]);
}];
export { render, staticRenderFns };